<template>
  <div>
    <b-row>
      <b-col cols="3" sm="12" md="4" lg="3" class="mt-4">
        <b-card no-body class="card-employee-task" style="margin-top: -4em">
          <b-card-body>
            <div
              class="
                employee-task
                d-flex
                justify-content-between
                align-items-center
              "
              @click="list()"
            >
              <b-media no-body id="s2">
                <b-media-aside class="mr-75">
                  <feather-icon
                    icon="ListIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0" id="s22">รายชื่อบัญชี ({{ totalRows }})</h6>
                </b-media-body>
              </b-media>
            </div>
            <hr />
            <div
              class="
                employee-task
                d-flex
                justify-content-between
                align-items-center
              "
              @click="add_show()"
            >
              <b-media no-body id="s3">
                <b-media-aside class="mr-75">
                  <feather-icon
                    icon="EditIcon"
                    size="20"
                    class="cursor-pointer"
                  />
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h6 class="mb-0" id="s33">สร้างใหม่</h6>
                </b-media-body>
              </b-media>
            </div>
          </b-card-body>
        </b-card>

        <b-card no-body class="card-apply-job" v-if="show_add == true">
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar size="42" :src="profile" />
              </b-media-aside>
              <b-media-body>
                <h5 class="mb-0">เพิ่มร้านค้าของคุณ</h5>
                <small class="text-muted">{{ name_user.name }}</small>
              </b-media-body>
            </b-media>
          </b-card-header>
          <b-card-body>
            <b-form @submit.prevent>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <p>ชื่อร้านค้า</p>
                    <b-input-group class="input-group-merge">
                      <b-form-input v-model="name" placeholder="กรอกชื่อร้าน" />
                    </b-input-group>
                    <b-alert v-if="check.name == true" variant="danger" show>
                      <span>Please Enter</span>
                    </b-alert>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <p>ประเภทธุรกิจ</p>
                    <b-input-group class="input-group-merge">
                      <b-form-select
                        v-model="selected"
                        :options="options"
                        :state="selected === null ? false : true"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <p>ประเภทธุรกิจย่อย</p>
                    <b-input-group class="input-group-merge">
                      <b-form-select
                        v-model="selected2"
                        :options="options2"
                        :state="selected2 === null ? false : true"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <!-- <b-col cols="12">
                  <b-form-group>
                    <p>รูปภาพ</p>
                    <b-input-group class="input-group-merge">
                      <b-form-file
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        type="file"
                        accept=".jpeg,.png,.jpg,GIF"
                        :hidden="true"
                        @change="onFileChange"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col> -->

                <b-col>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    block
                    class="mr-1"
                    @click="add_store()"
                  >
                    เพิ่มร้านค้า
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="9" sm="12" md="8" lg="9">
        <h2>รายชื่อบัญชี</h2>
        <hr />
        บัญชี ({{ totalRows }})
        <div
          class="custom-search d-flex justify-content-end"
          style="margin-top: -2em"
        >
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field === 'store_name'"
              class="text-nowrap"
              @click="
                goto_store(
                  props.row.id,
                  props.row.store_name,
                  props.row.line_oa
                )
              "
            >
              <b-avatar
                :src="props.row.line_oa ? props.row.line_oa.logo : null"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.store_name }}</span>
            </span>

            <span v-else-if="props.column.field === 'package'">
              <b-badge variant="success" v-if="props.row.package === 'Premium'">
                {{ props.row.package }}
              </b-badge>
              <b-badge variant="success" v-if="props.row.package === 'Premium Plus'">
                {{ props.row.package }}

              </b-badge>
              <b-badge variant="info" v-else> {{ props.row.package }} </b-badge>
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['5', '10', '15', '20']"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BInputGroup,
  BFormSelect,
  BAlert,
  BFormFile,
  BImg,
  BPagination,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import api from "@/api";
import store from "@/store";
import { VueGoodTable } from "vue-good-table";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BInputGroup,
    BFormSelect,
    BAlert,
    BFormFile,
    BImg,
    BPagination,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show_add: false,
      check_oa: true,
      data: "",
      totalRows: "",
      name_user: "",
      items_line: "",
      selected: null,
      selected2: null,
      name: null,
      code: null,
      file: null,
      profile: require("@/assets/images/portrait/small/avatar-s-20.jpg"), //โชว์รูปเฉยๆๆ
      options: [],
      options2: [],
      check: {
        name: false,
      },
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "ขื่อร้าน",
          field: "store_name",
        },
        {
          label: "สาขา",
          field: "branch",
        },
        {
          label: "แพ็กเกจ",
          field: "package",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },

  created() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", true);
  },
  destroyed() {
    this.$store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", this.menuHidden);
  },
  watch: {
    selected: function (val) {
      (this.options2 = []), this.loadtype_sub(val);
    },
  },
  mounted() {
    // this.loaddata_lineoa();
    this.name_user = JSON.parse(localStorage.getItem("userData"));
    this.loaddata();
    this.loadtype();
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  computed: {},
  methods: {
    onFileChange(e) {
      this.file = e.target.files[0];
      console.log("file", this.file);
      const image = e.target.files[0];
      this.profile = URL.createObjectURL(image); ///show
    },
    async loadtype() {
      const params = {
        id: this.name_user.user_main,
      };
      api
        .post("business_type", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.options = response.data.data;
          this.selected = response.data.data[0].value;
          this.loadtype_sub(this.selected);
        })
        .catch((error) => {});
    },
    async loadtype_sub(id) {
      const params = {
        id: id,
      };
      console.log("params :>> ", params);
      api
        .post("type_sub", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.options2 = response.data.type_sub;
          this.selected2 = response.data.type_sub[0].value;
        })
        .catch((error) => {});
    },
    async loaddata() {
      const params = {
        id: this.name_user.user_main,
      };

      api
        .post("getstore", params, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("response.data.stores :>> ", response.data.stores);
          this.rows = response.data.stores;
          this.totalRows = response.data.total;
          this.list();
        })
        .catch((error) => {
          // window.location.reload();
        });
    },
    // loaddata_lineoa() {
    //   var userData = JSON.parse(localStorage.getItem("userData"));
    //   const params = {
    //     store_id: localStorage.getItem("store_id"),
    //   };
    //   var access_token = localStorage.getItem("accessToken");
    //   api
    //     .post("check_connect_oa", params, {
    //       headers: {
    //         Authorization: "Bearer " + access_token,
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       this.items_line = response.data.data[0];
    //       localStorage.setItem("logo", response.data.data[0].logo);
    //       localStorage.setItem("name_oa", response.data.data[0].logo);
    //       console.log("this.items_line :>> ", this.items_line);
    //       if (response.data.total === 0) {
    //         this.$router.push("/lineoa/add");
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.check_oa = false;
    //     });
    // },
    goto_store(id, store_name, lineoa) {
      // console.log("goto_store :>> ", this.check_oa);
      localStorage.setItem("store_id", id);
      localStorage.setItem("store_name", store_name);
      if (!lineoa) {
        this.$router.push("/lineoa/add");
      } else {
        console.log("lineoa :>> ", lineoa);
        localStorage.setItem("lineoa.name", lineoa.name);
        localStorage.setItem("oneSelect", 1);
        store.commit("appConfig/SET_STROE_NAME", store_name);
        localStorage.setItem("logo", lineoa.logo);
       
        this.$router.push("/index");
      }
    },
    add_store(id) {
      this.check.name = false;
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("store_name", this.name);
      formData.append("code", this.code);
      formData.append("type_sub", this.selected2);
      formData.append("business_type", this.selected);
      formData.append("user_main", this.name_user.user_main);
      if (this.name) {
        this.$swal({
          title: "คุณต้องการบันทึก?",
          text: "เพิ่มร้านค้าของคุณ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึก",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            api
              .post("/addstore", formData, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("response", response);
                if (response) {
                  this.$swal({
                    icon: "success",
                    title: "success",
                    showConfirmButton: false,
                    timer: 3000,
                  });
                  this.loaddata();
                  this.check.name = false;
                  this.name = "";
                  this.code = "";
                  this.file = "";
                  this.profile = require("@/assets/images/portrait/small/avatar-s-20.jpg");
                }
              });
          }
        });
      } else {
        this.check.name = true;
        if (!this.name) {
          this.check.name = true;
          console.log("this.check.name :>> ", this.check.name);
        }

        this.$swal({
          title: "Error!",
          text: " ตรวจสอบข้อมูลใหม่อีกครั้ง!",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    del_store(id) {
      const params = {
        id: id,
      };
      this.$swal({
        title: "คุณต้องการลบผู้ใช้งาน ?",
        text: "คุณจะไม่สามารถย้อนกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ยืนยัน!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api
            .post("/del_store", params, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("accessToken"),
                "Content-Type": "application/json",
              },
            })
            .then((response) => {
              if (response) {
                this.loaddata();
                this.$swal({
                  icon: "success",
                  title: "ลบสำเร็จ!",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            });
        }
      });
    },
    list() {
      this.show_add = false;
      document.getElementById("s2").style.color = "#55b9f3";
      document.getElementById("s22").style.color = "#55b9f3";
      document.getElementById("s3").style.color = "";
      document.getElementById("s33").style.color = "";
    },
    add_show() {
      this.show_add = true;
      document.getElementById("s2").style.color = "";
      document.getElementById("s22").style.color = "";
      document.getElementById("s3").style.color = "#55b9f3";
      document.getElementById("s33").style.color = "#55b9f3";
    },
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-table.bordered td {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
}
.vgt-table.bordered th {
  border-bottom-color: #ebe9f1;
  border-left-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  background-color: #edeff0;
}
</style>
